<template>
  <header v-if="model" ref="annoRef" :class="['e-anno']">
    <CommonContainer class="container">
      <div v-if="showIcon" class="icon">
        <img :src="alertConfig.icon" alt="AI photo editor" />
      </div>
      <div v-if="!dangerouslyUseHTMLString" class="content">
        {{ alertConfig.content || message }}
      </div>
      <div v-else class="content" v-html="alertConfig.content"></div>
      <div v-if="showLastBtn" :class="['btn', `btn-${alertConfig.btn_style}`]" @click="btnLinkClick">
        {{ alertConfig.btn_text }}
      </div>
      <img
        v-if="showClose"
        class="close-icon"
        :src="getCloudAssets('/icons/common/close085.png')"
        alt="AI photo editor"
        @click="close"
      />
    </CommonContainer>
  </header>
</template>
<script lang="ts" setup>
import { PropType } from "vue"
import { IDecoratorType } from "@/composables/api/common"
import { getCloudAssets } from "@/utils/index"

const props = defineProps({
  dangerouslyUseHTMLString: {
    type: Boolean,
    default: true
  },
  showClose: {
    type: Boolean,
    default: true
  },
  onClose: {
    type: Function as PropType<(...args) => void>,
    default: null
  },
  appendTo: {
    type: String as PropType<string | HTMLElement>,
    default: "body"
  },
  customClass: {
    type: String,
    default: ""
  },
  message: {
    type: String,
    default: ""
  },
  visible: {
    type: Boolean,
    default: false
  }
})

// 头部icon 和 尾部按钮显示
const alertConfig = computed(() => {
  return useNavBarAlertConfig().value
})

// 显示头部icon
const showIcon = computed(() => {
  return useNavBarAlertConfig().value?.element.includes(IDecoratorType.Type1)
})

const showLastBtn = computed(() => {
  return useNavBarAlertConfig().value?.element.includes(IDecoratorType.Type2)
})

const btnLinkClick = useDebounceFn(() => {
  useLocationOpen(alertConfig.value.btn_url)
}, 300)

const emits = defineEmits(["update:visible"])

const model = computed({
  get() {
    return props.visible
  },
  set(val) {
    return emits("update:visible", val)
  }
})

function close() {
  model.value = false
  typeof props?.onClose === "function" && props.onClose()
}

function open() {
  model.value = true
}

defineExpose({
  open: open,
  close: close
})
</script>
<style lang="scss" scoped>
.e-anno {
  position: relative;
  z-index: 999;
  display: flex;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 52px;
  background-color: #000000;
  width: 100%;
  padding: 10px 0;
  overflow: hidden;

  @include device-max-width-600 {
    min-height: 40px;
  }
  @include device-max-width-375 {
    min-height: 36px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      display: flex;
      margin-right: 16px;
      img {
        height: 32px;
        aspect-ratio: 1 / 1;
        object-fit: contain;
      }
    }
    @include device-max-width-1920 {
      width: 100%;
    }
  }

  .box {
    height: 70px;
    width: 100%;
    background-color: aqua;
  }
  .content {
    position: relative;
    display: inline-block;
    // align-items: center;
    // justify-content: center;
    // padding: 0 32px;
    text-align: center;
    font-size: 16px;
    margin-right: 16px;
  }
  .btn {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    color: #000;
    border-radius: 1000px;
    justify-content: center;
    align-items: center;
    min-height: 32px;
    padding: 0 8px;
    text-align: center;
    margin-right: 16px;
    @include fontSemibold;
    &.btn-1 {
      background-color: #fff;
    }
    &.btn-2 {
      border: 1px solid #fff;
      color: #fff;
    }
    &.btn-3 {
      background: linear-gradient(90deg, #ffe050 -0.24%, #fff 100.26%);
    }
    &.btn-4 {
      border: 1px solid #ffe050;
      color: #ffe050;
    }
  }
  @include device-max-width-600 {
    .container {
      .icon {
        margin-right: 4px;
      }
    }

    .content {
      font-size: 12px;
      line-height: 14px;
      margin-right: 4px;
    }
    .btn {
      font-size: 12px;
      line-height: 14px;
      margin-right: 4px;
    }
  }

  .close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    @include device-max-width-600 {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
