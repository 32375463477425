<template>
  <Transition name="drawer-bottom">
    <div v-show="visible" class="cookie-drawer-container">
      <CommonContainer class="cookie-drawer-container-inner pix-wrapper">
        <div class="placeholder pix-col-md-1400-1 pix-col-lg-1920-4 pix-col-4"></div>
        <div
          class="cookie-drawer-container-wrapper pix-col-xs-375-4 pix-col-xs-600-4 pix-col-md-900-8 pix-col-md-1400-14 pix-col-lg-1920-16 pix-col-16"
        >
          <div class="cookie-drawer-container-wrapper-content">
            <p class="cookie-drawer-container-wrapper-content-title">
              {{ useTranslateI18n("common.cookies.drawer.header") }}
            </p>
            <ClientOnly>
              <p
                class="cookie-drawer-container-wrapper-content-desc"
                v-html="
                  useTranslateI18n(`common.cookies.drawer.content`, [
                    {
                      type: 'static',
                      class: 'drawer-cooker_text_1'
                    }
                  ])
                "
              ></p>
            </ClientOnly>
          </div>

          <div class="cookie-drawer-container-wrapper-btn-box">
            <span class="decline-text" @click="declineClick">
              {{ useTranslateI18n("common.cookies.drawer.decline") }}
            </span>
            <span class="cookie-drawer-container-wrapper-btn-box__item accept-btn" @click="handleAcceptAll">
              <CommonAnimationScrollText>{{
                useTranslateI18n("common.cookies.drawer.acceptText")
              }}</CommonAnimationScrollText>
            </span>
          </div>
        </div>
      </CommonContainer>
    </div>
  </Transition>
</template>
<script lang="ts" setup>
import { useCookieDialog } from "~~/composables/components/dialog/cookie/useCookieDialog"
import { LocalStorageKey } from "~~/constant/cache"
import { useCookiesStore } from "~~/store/cookies"

const visible = ref(false)
const { setSelectedOption } = useCookiesStore()

function handleAcceptAll() {
  setLocalStorage(LocalStorageKey.IsAllowCookies, "1")
  setSelectedOption("all")
  handleCloseDrawer()
  postAcceptAllIP()
}

function declineClick() {
  setLocalStorage(LocalStorageKey.IsAllowCookies, "1")
  setSelectedOption({})
  handleCloseDrawer()
}

function postAcceptAllIP() {
  useSelfPointer("/__static__/agree.txt")
}

function openDrawerIp() {
  useSelfPointer("/__static__/open_cookies_drawer.txt")
}

function handleCloseDrawer() {
  visible.value = false
}

const handleReadMore = () => {
  window.open(getCloudAssets("/policy/cookies.html"))
}

let readMoreEvent
onMounted(() => {
  if (process.client) {
    visible.value = !getLocalStorage(LocalStorageKey.IsAllowCookies)
    if (visible.value) {
      openDrawerIp()
    }
    let timeId = setTimeout(() => {
      readMoreEvent = useEventListener(document.querySelector(".drawer-cooker_text_1"), "click", handleReadMore)
      clearTimeout(timeId)
    })
  }
})
onBeforeMount(() => {
  readMoreEvent && readMoreEvent()
})
</script>
<style lang="scss" scoped>
.cookie-drawer-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 99;
  color: #000000;

  &-inner {
    width: 100%;
    height: 100%;
  }

  &-wrapper {
    position: relative;
    display: flex;
    background: #ffffff;
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06);
    border-radius: 16px 16px 0px 0px;

    &-content {
      padding: 32px 0 32px 32px;
      &-title {
        @include fontSemibold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      &-desc {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.55);
        :deep(.drawer-cooker_text_1) {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    &-btn-box {
      padding: 0 40px;
      padding-right: 32px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-btn-box__item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 159px;
      height: 37px;
      font-size: 14px;
      line-height: 17px;
      color: #16181a;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 1000px;
      cursor: pointer;
      @include fontSemibold;
    }

    .decline-text {
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.55);
      margin-right: 16px;
      width: max-content;
      cursor: pointer;
    }

    .accept-btn {
      background-color: #ffe050;
    }

    .close-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 12px;
      right: 16px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }
  }

  @include device-max-width-900 {
    .placeholder {
      display: none;
    }

    &-wrapper {
      &-btn-box {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        padding-right: 24px;
        .decline-text {
          font-size: 12px;
          line-height: 14px;
          margin-top: 10px;
          margin-right: 0;
        }
        .accept-btn {
          width: 149px;
        }
      }
      &-content {
        padding: 24px 0 24px 24px;
        &-desc {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  @include device-max-width-600 {
    &-wrapper {
      &-content {
        &-desc {
          max-height: 68px;
          overflow-y: scroll;
        }
      }
    }
  }
}
@media (max-width: 520px) {
  .cookie-drawer-container {
    // min-height: 202px;
    height: auto;

    &-wrapper {
      flex-direction: column;
      &-content {
        padding: 24px 24px 0;
        &-title {
          font-size: 18px;
          line-height: 21px;
        }

        &-desc {
          font-size: 12px;
          line-height: 14px;
        }
      }

      &-btn-box {
        flex-wrap: nowrap;
        flex-direction: column;
        width: 100%;
        padding: 0 20px;
        margin-top: 16px;
        margin-bottom: 30px;
        .accept-btn {
          width: 100%;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
